<template>
  <va-card title="Vacancy Request">
    <div class="mb-3">
      <h2 class="display-4 mb-1">Name</h2>
      <span>{{ name }}</span>
    </div>

    <div class="mb-3">
      <h2 class="display-4 mb-1">Email</h2>
      <span>{{ email }}</span>
    </div>

    <div v-if="comment" class="mb-3">
      <h2 class="display-4 mb-1">Comment</h2>
      <span>{{ comment }}</span>
    </div>
    <div v-if="file" class="mb-3">
      <h2 class="display-4 mb-1">Resume</h2>
      <a target="_blank" :href="adminUrl + file" class="link mr-4">
        {{ file }}
      </a>
    </div>

    <div class="mb-3" v-if="created_at">
      <h2 class="display-4 mb-1">Created at</h2>
      <span>{{ created_at }}</span>
    </div>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      name: '',
      email: '',
      comment: '',
      created_at: '',

      file: null,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    locale () {
      return Vue.i18n.locale()
    },
    adminUrl () {
      return process.env.VUE_APP_AGRO_ADMIN
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/vacancy-request/${this.$attrs.id}?lang=${this.locale}`)
        .then(response => {
          this.name = response.data.name
          this.email = response.data.email
          this.comment = response.data.comment
          const date = new Date(response.data.created_at * 1000)
          this.created_at = date.toLocaleDateString('uk-UA') + ' ' + date.toLocaleTimeString('uk-UA')
          this.file = response.data.file
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
